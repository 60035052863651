import { MainNavMenuAsButton, MainNavMenuLink } from "@compono/ui";
import { CandidatesOutlineIcon } from "../icons/CandidatesOutlineIcon";

export const DevelopNav = () => {
  return (
    <MainNavMenuAsButton
      icon={CandidatesOutlineIcon}
      label="Develop"
      data-menu-button="true"
    >
      <MainNavMenuLink href={`/courses`} label="Courses" />
    </MainNavMenuAsButton>
  );
};
